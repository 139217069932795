import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/login.vue')
  },
  {
    path: '/doTest',
    name: 'DoText',
    component: () => import('../views/DoTest/doTest.vue')
  },
  {
    path: '/record',
    name: 'Record',
    component: () => import('../views/Record/record.vue')
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('../views/Setting/setting.vue')
  },
  {
    path: '/setting-one',
    name: 'SettingOne',
    component: () => import('../views/Setting/settingOne.vue')
  },
  {
    path: '/add-lock-record',
    name: 'AddLockRecord',
    component: () => import('../views/AddLockRecord/addLockRecord.vue')
  },
  {
    path: '/setting-record',
    name: 'SettingRecord',
    component: () => import('../views/SettingRecord/settingRecord.vue')
  },
  {
    path: '/remove',
    name: 'Remove',
    component: () => import('../views/Remove/remove.vue')
  },
  {
    path: '/open-api',
    name: 'OpenApi',
    component: () => import('../views/OpenApi/open-api.vue')
  },
  {
    path: '/bind-parts',
    name: 'BindParts',
    component: () => import('../views/BindParts/bindParts.vue')
  },
  {
    path: '/apply-parts',
    name: 'ApplyParts',
    component: () => import('../views/BindParts/applyParts.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
